import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

const IndexPage = () => {

	var seo = { metaTitle:'Landing page | Dapit', metaDescription:'Landing page to a little portfolio page of Dapit.', shareImage:"/images/LandingPage-Dapit.png"};

	return (
		<Layout pageen="/" pagelb="/" seo={seo}>
			<section className="call4Action">
				<div className="box">
					<div className="title">
						<h1>
							Dylan Mertz<br/>
							Dapit<br/>
							A little portfolio
						</h1>
					</div>
					<button className="call4Action-contact">
						<a href="/contact">Contact</a>
					</button>
					<button className="call4Action-works">
						<a href="#nocturnal">See my works</a>
					</button>
				</div>
				<div className="karbs d-flex justify-content-end justify-content-lg-between">
					<img src="/images/roseshape.svg" alt="Image, Banner Image of Karbs swimming."/>
					<img src="/images/karbs.svg" alt="Image, Banner Image of Karbs swimming."/>
				</div>
			</section>
			<section className="container-fluid block mt-5 mb-5" id="nocturnal">
				<div className="row mt-5">
					<div className="col-12 col-lg-5 title">
						<p className="date">20 April 2021</p>
						<h2>Nocturnal E-Sports A.S.B.L</h2>
						<div className="d-none d-lg-inline">
							<h3>Website</h3>
							<p>
								Nocturnal E-sports new website is a platform where they post their news and where you can find detailed information about their teams in the different E-sports. About those teams you find detailed informations about the tournament with their result in which they compete.
							</p>
							<p>
								The page also introduces their members and what they do.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fas fa-globe"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 image">
						<img src="/images/Nocturnal-Website.jpg" alt="Image, Nocturnal E-sports Screenshot of the website's landing page."/>
						<div className="caption">
							<div className="box">
								<span className="header">Nocturnal E-sport</span><br/>
								'Landing Page'
							</div>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Website</h3>
						<p>
							Nocturnal E-sports new website is a platform where they post their news and where you can find detailed information about their teams in the different E-sports. About those teams you find detailed informations about the tournament with their result in which they compete.
						</p>
						<p>
							The page also introduces their members and what they do.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fas fa-globe"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-facebook-square"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block mb-5">
				<div className="row">
					<div className="col-12 col-lg-5 order-lg-2 title">
						<p className="date">09 December 2020</p>
						<h2>MX-5 NA in night</h2>
						<div className="d-none d-lg-inline">
							<h3>Photography</h3>
							<p>
								Shooting for Sacha with his quiet newly treasure of an old Mazda Mx-5 NA from 1996.
							</p>
							<ul>
								<li>
									<a href="http://www.nocturnal.lu" target="_blank">
										<i className="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-lg-7 order-lg-1 image">
						<div className="carousel slide carousel-fade" id="mx5Carousel" data-bs-ride="carousel" data-bs-interval="false">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img src="/images/mx5-first-night-1.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-2-v2.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-5.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
								<div className="carousel-item">
									<img src="/images/mx5-first-night-6.jpg" alt="Image, MX5-Na night shooting"/>
								</div>
							</div>
						</div>
						<div className="caption">
							<div className="box">
								<span className="header">MX-5 NA</span><br/>
								'Night shoot'
							</div>
						</div>
						<div className="carousel-control-box">
							<button className="carousel-control carousel-control-prev" type="button" data-bs-target="#mx5Carousel" data-bs-slide="prev">
								<i class="fas fa-caret-left"></i>
							</button>
							<button className="carousel-control carousel-control-next" type="button" data-bs-target="#mx5Carousel" data-bs-slide="next">
								<i class="fas fa-caret-right"></i>
							</button>
						</div>
					</div>
					<div className="col-12 d-inline d-lg-none content">
						<h3>Photography</h3>
						<p>
							Shooting for Sacha with his quiet newly treasure of an old Mazda Mx-5 NA from 1996.
						</p>
						<ul>
							<li>
								<a href="http://www.nocturnal.lu" target="_blank">
									<i className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container-fluid block more mb-5">
				<div className="">
					<div className="col-12 more">
						<a href="/portfolio">See all of my works</a>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
