import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Markdown from "react-markdown";

const Footer = () => (
	<footer>
		<div className="slogan">Dapthis, Dapthat, Dapeverything</div>
		<div class="social d-flex justify-content-center">
			<a href="http://www.dapit.lu" target="_blank">
				<i className="fas fa-torii-gate"></i>
			</a>
			<a href="https://t.me/daptele" target="_blank">
				<i className="fab fa-telegram-plane"></i>
			</a>
			<a href="https://t.me/joinchat/T90sf6fhN8BTLZE6" target="_blank">
				<i className="fas fa-rss-square"></i>
			</a>
			<a href="https://www.instagram.com/dapitinsta/" target="_blank">
				<i className="fab fa-instagram"></i>
			</a>
		</div>
		<div class="author">
			Developed and designed by
			<a href="http://www.dapit.lu" target="_blank">
				<img src="/images/dapit-logo-darkgray.png" alt="Logo, Dapit"/>
			</a>
		</div>
	</footer>
);

export default Footer;
